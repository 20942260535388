import { getRequest, postRequest } from './requests';

const API_URL: string = process.env.WAGTAIL_API_URL || '';
const NEXT_PUBLIC_API_URL: string =
    process.env.NEXT_PUBLIC_WAGTAIL_API_URL || '';

interface RequestOptions {
    headers?: Record<string, string>;
}

export async function getPage(
    path: string,
    params: Record<string, any> = {},
    options: RequestOptions = {}
): Promise<any> {
    params = { htmlPath: path, ...params };
    return await getRequest(`${API_URL}/v1/page_by_path/`, params, options);
}

export async function getSearch(
    params: Record<string, any> = {}
): Promise<any> {
    return await getRequest(`${API_URL}/v1/page_search/`, params);
}

export async function getPasswordProtectedPage(
    restrictionId: string,
    pageId: string,
    params: Record<string, any> = {},
    options: RequestOptions = {}
): Promise<any> {
    return await postRequest(
        `${NEXT_PUBLIC_API_URL}/v1/password_protected_page/${restrictionId}/${pageId}/`,
        params,
        options
    );
}

export async function getAllPages(): Promise<any> {
    return await getRequest(`${API_URL}/v1/page_relative_urls/`, {}, {});
}

export async function getPagePreview(
    contentType: string,
    token: string,
    params: Record<string, any> = {},
    options: RequestOptions = {}
): Promise<any> {
    params = { contentType, token, ...params };
    return await getRequest(`${API_URL}/v1/page_preview/`, params, options);
}

export async function getPublicViewData(
    slug: string,
    params: Record<string, any> = {},
    options: RequestOptions = {}
): Promise<any> {
    return await getRequest(
        `${NEXT_PUBLIC_API_URL}/v1/external_view_data/${slug}/`,
        params,
        options
    );
}

export async function getViewData(
    slug: string,
    params: Record<string, any> = {},
    options: RequestOptions = {}
): Promise<any> {
    return await getRequest(
        `${API_URL}/v1/external_view_data/${slug}/`,
        params,
        options
    );
}

export async function getRedirect(
    path: string,
    params: Record<string, any> = {},
    options: RequestOptions = {}
): Promise<any> {
    params = { htmlPath: path, ...params };
    return await getRequest(`${API_URL}/v1/redirect_by_path/`, params, options);
}

export class WagtailApiResponseError extends Error {
    response: any; // Add the 'response' property declaration

    constructor(res: any, url: any, params: any) {
        super(
            `${res.statusText}. Url: ${url}. Params: ${JSON.stringify(params)}`
        );
        this.name = 'WagtailApiResponseError';
        this.response = res; // Add the response as a property
    }
}
