'use client';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import s from './PasswordProtectedPage.module.scss';
import {
    getPasswordProtectedPage,
    WagtailApiResponseError,
} from '../../api/wagtail';
import LazyContainers from '../LazyContainers';

const PasswordProtectedPage = ({
    restrictionId,
    pageId,
    csrfToken,
}: {
    restrictionId: any;
    pageId: any;
    csrfToken: any;
}) => {
    const [values, setValues] = useState({ password: '' });
    const [error] = useState(null);
    const [pageData, setPageData] = useState(null);

    const router = useRouter();
    const currentLang = router.locale || 'nl';

    const handleFormChange = async (e: any) => {
        e.preventDefault();

        try {
            const { json } = await getPasswordProtectedPage(
                restrictionId,
                pageId,
                {
                    ...values,
                },
                {
                    headers: {
                        'X-CSRFToken': csrfToken,
                    },
                }
            );

            setPageData(json);
        } catch (e) {
            if (!(e instanceof WagtailApiResponseError)) {
                throw e;
            }

            // switch (e.response.status) {
            //     case 403:
            //         setError('Forbidden');
            //         break;
            //     case 401:
            //         setError('Invalid password');
            //         break;
            //     default:
            //         setError('Technical issues');
            //         break;
            // }
        }
    };

    const handlePasswordChange = (e: any) => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    };

    if (pageData) {
        const { componentName, componentProps } = pageData;
        const Component = LazyContainers[componentName];
        if (!Component) {
            return <h1>Component {componentName} not found</h1>;
        }
        return (
            <Component
                {...(typeof componentProps === 'object' ? componentProps : {})}
            />
        );
    }

    return (
        <div className={s.PasswordProtectedPage}>
            <div className={s.PasswordProtectedPage__inner}>
                <h1>
                    {currentLang == 'en'
                        ? 'Password required'
                        : 'Wachtwoord is vereist'}
                </h1>
                <p>
                    {currentLang == 'en'
                        ? 'You need a password to access this website'
                        : 'Om toegang te krijgen tot deze website heeft u een wachtwoord nodig'}
                </p>

                {!!error && <p>{error}</p>}
                <form
                    method="post"
                    onSubmit={handleFormChange}
                    className={s.PasswordProtectedPage__form}>
                    <input
                        className={s.PasswordProtectedPage__input}
                        type="password"
                        name="password"
                        onChange={handlePasswordChange}
                        placeholder="Password"
                    />
                    <button className="wb-btn" type="submit">
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

PasswordProtectedPage.propTypes = {
    restrictionId: PropTypes.number.isRequired,
    pageId: PropTypes.number.isRequired,
    csrfToken: PropTypes.string.isRequired,
};

export default PasswordProtectedPage;
