import querystring from 'querystring';
import {
    keysToCamelFromSnake,
    keysToSnakeFromCamel,
} from '../utils/caseconverters';

interface RequestOptions {
    headers?: Record<string, string>;
}

export async function getRequest(
    url: string,
    params: Record<string, any> = {},
    options: RequestOptions = {}
): Promise<any> {
    params = params || {};
    params = keysToSnakeFromCamel(params);

    let headers = options?.headers || {};
    headers = {
        'Content-Type': 'application/json',
        ...headers,
    };
    const queryString = querystring.stringify(params);
    const res = await fetch(`${url}?${queryString}`, { headers });

    if (res.status < 200 || res.status >= 300) {
        const error = new WagtailApiResponseError(res, url, params);
        throw error;
    }

    const json = await res.json();
    return {
        headers: res.headers,
        json: keysToCamelFromSnake(json),
    };
}

export async function postRequest(
    url: string,
    params: Record<string, any> = {},
    options: RequestOptions = {}
): Promise<any> {
    params = params || {};
    params = keysToSnakeFromCamel(params);

    let headers = options?.headers || {};
    headers = {
        'Content-Type': 'application/json',
        ...headers,
    };
    const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(params),
        headers,
    });

    if (res.status < 200 || res.status >= 300) {
        const error = new WagtailApiResponseError(res, url, params);
        throw error;
    }

    const json = await res.json();
    return {
        headers: res.headers,
        json: keysToCamelFromSnake(json),
    };
}

export class WagtailApiResponseError extends Error {
    response;
    constructor(res: Response, url: any, params: any) {
        super(
            `${res.statusText}. Url: ${url}. Params: ${JSON.stringify(params)}`
        );
        this.name = 'WagtailApiResponseError';
        this.response = res;
    }
}
